/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { convertCurrencySummaryLocation } from '../helpers';
import { Translation } from '../Helpers/Translation';
import { FiEdit2 } from 'react-icons/fi';

@inject('AppStore')
@observer
class SummaryStep extends React.Component {
  store = this.props.AppStore;

  render() {
    return (
      <div className="step-summary-content step-image-content">
        <div className="left">
          {/* <div className="heading">
            <h1>
              <Translation
                lang={this.store.page.lang}
                t="summary_title"
                params={{ model: this.store.data.name }}
              />
            </h1>
          </div> */}

          <div className="image" style={{ position: 'relative' }}>
            {this.store.gallery.map(
              (item, i) =>
                this.store.image_mode === item.type && (
                  <img
                    key={i}
                    alt="model"
                    className="animate__animated animate__fadeIn"
                    src={this.store.getPicture}
                    style={{
                      position: 'absolute',
                      width: '100%',
                      left: 0,
                      top: 0,
                    }}
                  />
                )
            )}

            <div
              className="image-changer"
              style={{ position: 'absolute', bottom: 10, right: 10 }}
            >
              <div className="image-changer-inner">
                {this.store.gallery.map((item, i) => (
                  <a
                    key={i}
                    className={`${
                      this.store.image_mode == item.type ? 'active' : ''
                    }`}
                    href="#"
                    onClick={(e) => this.store.set_image_mode(e, item.type)}
                  >
                    {i + 1}
                    {/* {item.type} */}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="options-wrapper">
          <div
            className="model-name"
            dangerouslySetInnerHTML={{ __html: this.store.data.name }}
          ></div>

          {/* <div className="standards-wrapper">
            <h3>
              <Translation lang={this.store.page.lang} t="summary_standards" />
            </h3>
            <div className="standards">
              <div className="standards-left">
                <pre>{this.store.data[this.store.langify("standards")]}</pre>
              </div>
              <div className="standards-right">
                <pre>{this.store.data[this.store.langify("standards_right")]}</pre>
              </div>
            </div>
          </div> */}
          <div className="upgrades-wrapper">
            <div className="option-group-title">
              <div
                className="selectedModel__subtitle--top uc"
                style={{ borderColor: `${this.store.data.color}` }}
              >
                ORDER
              </div>
              <div className="selectedModel__subtitle--bottom">SUMMARY</div>
            </div>
            <div className="upgrades">
              {Object.keys(this.store.summary.upgradeOptionsDisplay).map(
                (step, i) => (
                  <div key={i} className="">
                    <div
                      style={{
                        paddingBottom: 10,
                        paddingTop: 10,
                        borderBottom: '1px solid #ddd',
                      }}
                    >
                      <div className="upgradeoption-step">{step}</div>
                      {Object.keys(
                        this.store.summary.upgradeOptionsDisplay[step]
                      ).map((option_group_title, j) => (
                        <div key={j}>
                          {option_group_title !== 'null' && (
                            <div
                              className="upgradeoption-option-group-title"
                              style={{ marginLeft: 35 }}
                            >
                              {option_group_title}
                            </div>
                          )}

                          {Object.keys(
                            this.store.summary.upgradeOptionsDisplay[step][
                              option_group_title
                            ]
                          ).map((option_group, k) => (
                            <React.Fragment key={k}>
                              {option_group_title && (
                                <div
                                  className="upgradeoption-option-group"
                                  style={{
                                    marginLeft:
                                      option_group_title !== 'null' ? 45 : 35,
                                  }}
                                >
                                  {option_group}
                                </div>
                              )}

                              {this.store.summary.upgradeOptionsDisplay[step][
                                option_group_title
                              ][option_group].map((option, l) => (
                                <div
                                  className="upgradeoption-option option-row"
                                  key={l}
                                  style={{
                                    marginLeft:
                                      option_group_title !== 'null' ? 55 : 45,
                                  }}
                                >
                                  <span
                                    className="text"
                                    style={{
                                      marginLeft: 10,
                                    }}
                                  >
                                    <div
                                      className="upgradeoption-option"
                                      dangerouslySetInnerHTML={{
                                        __html: option.title,
                                      }}
                                    ></div>
                                  </span>
                                  <span
                                    className="upgradeoption-edit"
                                    style={{
                                      cursor: 'pointer',
                                      color: this.store.data.color,
                                      marginRight: 10,
                                      fontWeight: 'bold',
                                      padding: 5,
                                    }}
                                    onClick={() => {
                                      this.store.goToOption(option);
                                    }}
                                  >
                                    <FiEdit2 />
                                  </span>

                                  <span
                                    className="price animate__animated animate__fadeIn"
                                    style={{
                                      color: this.store.data.color,
                                      fontWeight: 'bold',
                                      flex: 1,
                                      textAlign: 'right',
                                    }}
                                  >
                                    {convertCurrencySummaryLocation(
                                      option.price,
                                      this.store.location,
                                      false,
                                      this.store.data.exclusive
                                    )}
                                  </span>
                                </div>
                              ))}
                            </React.Fragment>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="purchase-wrapper">
            <div className="purchase-price">
              <div className="purchase-price-text">
                Base Boat Price with Engine
              </div>
              <div className="purchase-price-amount">
                {convertCurrencySummaryLocation(
                  this.store.summary.engine_price,
                  this.store.location
                )}
              </div>
            </div>
            <div className="purchase-price" style={{ marginTop: 15 }}>
              <div className="purchase-price-text">Selected Options Price</div>
              <div className="purchase-price-amount">
                {convertCurrencySummaryLocation(
                  this.store.summary.selections_price,
                  this.store.location
                )}
              </div>
            </div>
            <hr />
            <div className="purchase-price">
              <div className="purchase-price-text">
                <strong>Total Purchase Price *</strong>
              </div>
              <div className="purchase-price-amount">
                <strong>
                  {convertCurrencySummaryLocation(
                    this.store.summary.purchase_price,
                    this.store.location,
                    false
                  )}
                </strong>
              </div>
            </div>
            <div
              className="purchase-price reservation"
              style={{ display: 'none' }}
            >
              <div className="purchase-price-text">Reservation Fee</div>
              <div className="purchase-price-amount">
                {convertCurrencySummaryLocation(
                  this.store.summary.due_today_price,
                  this.store.location
                )}
              </div>
            </div>
            <div style={{ marginTop: 35, fontSize: 12 }}>
              *Purchase Price including selected options & delivery but excludes
              taxes, title and registration processing.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SummaryStep;
