import React from 'react';
import { inject, observer } from 'mobx-react';

@inject('AppStore')
@observer
class Hint extends React.Component {
  render() {
    return (<React.Fragment></React.Fragment>)
  }
}

export default Hint;
