/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import ApiService from "../../../services/api.service";
import { IconBoutique } from "../icons/IconBoutique";
import { IconBuildYours } from "../icons/IconBuildYours";
import { IconConfigurations } from "../icons/IconConfigurations";
import { IconDashboard } from "../icons/IconDashboard";
import { IconExperience } from "../icons/IconExperience";
import { IconOrderHistory } from "../icons/IconOrderHistory";
import { IconPaymentMethod } from "../icons/IconPaymentMethod";
import { IconProfile } from "../icons/IconProfile";
import { IconSignout } from "../icons/IconSignOut";

const Nav = () => {
  const apiService = new ApiService();
  const [user, setUser] = React.useState({});

  const init = async () => {
    const user_data = await apiService.checkUser();

    if (user_data) {
      setUser(user_data.user);
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  const onSignout = async (e) => {
    e.preventDefault();

    await apiService.logout();

    window.location.href = "https://donzi-yachts.com";
  };

  return (
    <div className="account-navigation" style={{ width: 300 }}>
      <ul className="tds-list navigation ">
        <li className="navigation-link">
          <a href="/account">
            <div className="scrim">
              <IconDashboard />
            </div>
            Dashboard
          </a>
        </li>

        <li className="navigation-link">
          <Link to="/account/profile">
            <div className="scrim">
              <IconProfile />
            </div>
            Profile
          </Link>
        </li>

        <li className="navigation-link disabled">
          <Link
            to="/account/payment-methods"
            onClick={(e) => e.preventDefault()}
          >
            <div className="scrim">
              <IconPaymentMethod />
            </div>
            Payment Method
          </Link>
        </li>

        {user && user.location && (
          <li className="navigation-link">
            <a
              href={`/${user.location.name}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="scrim">
                <IconBuildYours />
              </div>
              Build Yours
            </a>
          </li>
        )}

        <li className="navigation-link">
          <a
            href="https://donzi-yachts.com/boutique"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="scrim">
              <IconBoutique />
            </div>
            Boutique
          </a>
        </li>

        <li className="navigation-link disabled">
          <a
            href="/"
            onClick={(e) => e.preventDefault()}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="scrim">
              <IconExperience />
            </div>
            Donzi Life Experiences
          </a>
        </li>

        <li className="navigation-link ">
          <Link to="/account/configurations">
            <div className="scrim">
              <IconConfigurations />
            </div>
            Configurations
          </Link>
        </li>

        <li className="navigation-link disabled">
          <Link to="/account/order-history" onClick={(e) => e.preventDefault()}>
            <div className="scrim">
              <IconOrderHistory />
            </div>
            Order History
          </Link>
        </li>

        <li className="navigation-link ">
          <a href="#" onClick={onSignout}>
            <div className="scrim">
              <IconSignout />
            </div>
            Sign Out
          </a>
        </li>
      </ul>
    </div>
  );
};
export default Nav;
