import React from 'react';
import './SpecPopup.css';
import { AiOutlineClose } from 'react-icons/ai';
import { appConfig } from '../config';

export const SpecPopup = ({ model, image }) => {
  const [visible, setVisible] = React.useState(false);

  const show = () => {
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
  };

  return (
    <div className={`spec_popup ${visible ? 'opened' : ''}`}>
      <div className="spec_popup__button" onClick={show}>
        Standard Specifications
      </div>
      <div className="spec_popup__modal">
        <div className="spec_popup__close" onClick={hide}>
          <AiOutlineClose fontSize="32" />
        </div>

        <div className="spec_popup__container">
          <div className="spec_popup__image">
            <div
              className="selectedModel__title"
              dangerouslySetInnerHTML={{
                __html: model.name,
              }}
            ></div>

            <img src={image} alt="" />
          </div>
          <div className="spec_popup__content">
            <div>
              <div className="selectedModel__tech">
                <div className="selectedModel__subtitle">
                  <div className="selectedModel__subtitle--top">TECHNICAL</div>
                  <div className="selectedModel__subtitle--bottom">
                    SPECIFICATIONS
                  </div>
                </div>
                <div className="selectedModel__specs">
                  <div className="selectedModel__spec">
                    <div className="selectedModel__spec--key">Length</div>
                    <div className="selectedModel__spec--text">
                      {model.standard_spec && model.standard_spec.length_m}
                    </div>
                  </div>
                  <div className="selectedModel__spec">
                    <div className="selectedModel__spec--key">Beam</div>
                    <div className="selectedModel__spec--text">
                      {model.standard_spec && model.standard_spec.beam}
                    </div>
                  </div>
                  {model.standard_spec && model.standard_spec.beam_opened && (
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">
                        Beam with open Side Teracces
                      </div>
                      <div className="selectedModel__spec--text">
                        {model.standard_spec && model.standard_spec.beam_opened}
                      </div>
                    </div>
                  )}
                  <div className="selectedModel__spec">
                    <div className="selectedModel__spec--key">Draft</div>
                    <div className="selectedModel__spec--text">
                      {model.standard_spec && model.standard_spec.draft}
                    </div>
                  </div>
                  <div className="selectedModel__spec">
                    <div className="selectedModel__spec--key">Displacement</div>
                    <div className="selectedModel__spec--text">
                      {model.standard_spec && model.standard_spec.displacement}
                    </div>
                  </div>
                  <div className="selectedModel__spec">
                    <div className="selectedModel__spec--key">Passengers</div>
                    <div className="selectedModel__spec--text">
                      {model.standard_spec && model.standard_spec.passengers}
                    </div>
                  </div>
                  <div className="selectedModel__spec">
                    <div className="selectedModel__spec--key">Berths</div>
                    <div className="selectedModel__spec--text">
                      {model.standard_spec && model.standard_spec.berths}
                    </div>
                  </div>
                  <div className="selectedModel__spec">
                    <div className="selectedModel__spec--key">Fuel Cap.</div>
                    <div className="selectedModel__spec--text">
                      {model.standard_spec && model.standard_spec.fuel}
                    </div>
                  </div>
                  <div className="selectedModel__spec">
                    <div className="selectedModel__spec--key">Range</div>
                    <div className="selectedModel__spec--text">
                      {model.standard_spec && model.standard_spec.range}
                    </div>
                  </div>
                  <div className="selectedModel__spec">
                    <div className="selectedModel__spec--key">
                      Fresh Water Cap.
                    </div>
                    <div className="selectedModel__spec--text">
                      {model.standard_spec && model.standard_spec.water}
                    </div>
                  </div>
                  <div className="selectedModel__spec">
                    <div className="selectedModel__spec--key">
                      Classification
                    </div>
                    <div className="selectedModel__spec--text">
                      {model.standard_spec &&
                        model.standard_spec.classification}
                    </div>
                  </div>
                </div>
              </div>

              <div className="selectedModel__engines">
                <div className="selectedModel__subtitle">
                  <div className="selectedModel__subtitle--top">ENGINE</div>
                  <div className="selectedModel__subtitle--bottom">TYPES</div>
                </div>

                {model.standard_spec && model.standard_spec.engine1_type && (
                  <div className="selectedModel__engine">
                    <table>
                      <tbody>
                        <tr>
                          <td>Engine Type</td>
                          <td>{model.standard_spec.engine1_type}</td>
                        </tr>
                        <tr>
                          <td>Propulsion</td>
                          <td>{model.standard_spec.engine1_propulsion}</td>
                        </tr>
                        <tr>
                          <td>Fuel Type</td>
                          <td>{model.standard_spec.engine1_fueltype}</td>
                        </tr>
                        <tr>
                          <td>Engines</td>
                          <td>{model.standard_spec.engine1_engine}</td>
                        </tr>
                        <tr>
                          <td>Speed</td>
                          <td>{model.standard_spec.engine1_speed}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}

                {model.standard_spec && model.standard_spec.engine2_type && (
                  <div className="selectedModel__engine">
                    <table>
                      <tbody>
                        <tr>
                          <td>Engine Type</td>
                          <td>{model.standard_spec.engine2_type}</td>
                        </tr>
                        <tr>
                          <td>Propulsion</td>
                          <td>{model.standard_spec.engine2_propulsion}</td>
                        </tr>
                        <tr>
                          <td>Fuel Type</td>
                          <td>{model.standard_spec.engine2_fueltype}</td>
                        </tr>
                        <tr>
                          <td>Engines</td>
                          <td>{model.standard_spec.engine2_engine}</td>
                        </tr>
                        <tr>
                          <td>Speed</td>
                          <td>{model.standard_spec.engine2_speed}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}

                {model.standard_spec && model.standard_spec.engine3_type && (
                  <div className="selectedModel__engine">
                    <table>
                      <tbody>
                        <tr>
                          <td>Engine Type</td>
                          <td>{model.standard_spec.engine3_type}</td>
                        </tr>
                        <tr>
                          <td>Propulsion</td>
                          <td>{model.standard_spec.engine3_propulsion}</td>
                        </tr>
                        <tr>
                          <td>Fuel Type</td>
                          <td>{model.standard_spec.engine3_fueltype}</td>
                        </tr>
                        <tr>
                          <td>Engines</td>
                          <td>{model.standard_spec.engine3_engine}</td>
                        </tr>
                        <tr>
                          <td>Speed</td>
                          <td>{model.standard_spec.engine3_speed}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}

                {model.standard_spec && model.standard_spec.engine4_type && (
                  <div className="selectedModel__engine">
                    <table>
                      <tbody>
                        <tr>
                          <td>Engine Type</td>
                          <td>{model.standard_spec.engine4_type}</td>
                        </tr>
                        <tr>
                          <td>Propulsion</td>
                          <td>{model.standard_spec.engine4_propulsion}</td>
                        </tr>
                        <tr>
                          <td>Fuel Type</td>
                          <td>{model.standard_spec.engine4_fueltype}</td>
                        </tr>
                        <tr>
                          <td>Engines</td>
                          <td>{model.standard_spec.engine4_engine}</td>
                        </tr>
                        <tr>
                          <td>Speed</td>
                          <td>{model.standard_spec.engine4_speed}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>

              {model.extra_features && (
                <div className="selectedModel__standard">
                  <div className="selectedModel__subtitle">
                    <div className="selectedModel__subtitle--top">
                      EXTRA INCLUDED
                    </div>
                    <div className="selectedModel__subtitle--bottom">
                      STANDARD FEATURES
                    </div>
                  </div>
                  <div
                    className="selectedModel__standardspecs"
                    style={{ paddingTop: 0 }}
                  >
                    <pre
                      dangerouslySetInnerHTML={{
                        __html: model.extra_features,
                      }}
                    ></pre>
                  </div>
                </div>
              )}

              <div className="selectedModel__standard">
                <div className="selectedModel__subtitle">
                  <div className="selectedModel__subtitle--top">STANDARD</div>
                  <div className="selectedModel__subtitle--bottom">
                    SPECIFICATIONS
                  </div>
                </div>
                <div className="selectedModel__standardspecs">
                  <div className="selectedModel__tabs">
                    {model.tabs &&
                      model.tabs.map((x, i) => (
                        <div
                          className={`selectedModel__tabs--item selected`}
                          key={i}
                        >
                          <div className="selectedModel__tabs--title">
                            {x[0]}
                          </div>
                          <div
                            className={`selectedModel__tabs--content animate__animated animate__fadeIn`}
                          >
                            <pre dangerouslySetInnerHTML={{ __html: x[1] }} />
                          </div>
                        </div>
                      ))}
                  </div>
                  <div>
                    Specifications are based on available information at the
                    time. Actual specifications may vary. Donzi Yachts
                    reserves the right to change product line specifications,
                    equipment, color scheme and pricing, or to discontinue
                    models at any time without notice or obligation.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
