import React from "react";
import { Link } from "react-router-dom";

export const DashboardCard = (props) => {
  return (
    <div
      className="dashboard-card"
      style={{
        backgroundImage: `url(${props.background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        filter: `grayscale(${props.disabled ? 1 : 0})`,
      }}
    >
      {props.link ? (
        <Link to={props.link} className="dashboard-link">
          <div className="dashboard-title">{props.title}</div>
        </Link>
      ) : props.disabled ? (
        <div className="dashboard-link">
          <div className="dashboard-title">{props.title}</div>
        </div>
      ) : (
        <a
          href={props.href}
          target="_blank"
          rel="noopener noreferrer"
          className="dashboard-link"
        >
          <div className="dashboard-title">{props.title}</div>
        </a>
      )}
    </div>
  );
};
