import React from 'react';
import { inject, observer } from 'mobx-react';
import { appConfig } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { convertCurrencyLocation } from '../helpers';
import { Loading } from '../Components/Loading';
import { AiOutlineClose } from 'react-icons/ai';
import '../app.css';
import ApiService from '../services/api.service';
import _ from 'lodash';
import { toJS } from 'mobx';

@inject('AppStore')
@observer
class Model extends React.Component {
  store = this.props.AppStore;
  apiService = new ApiService();

  async componentDidMount() { }

  render() {
    return (
      <div
        className={`modelSelector__item animate__animated animate__zoomIn ${this.props.store.selectedModel &&
          this.props.store.selectedModel.id === this.props.model.id &&
          'selected'
          } ${this.props.model.coming_soon ? 'coming_soon' : ''}`}
        onClick={() => {
          if (this.props.model.coming_soon) {
            if (this.props.model.web_url) {
              window.open(this.props.model.web_url);
            } else {
              return;
            }
          }
          this.props.store.selectModel(this.props.model);

          // setTimeout(() => {
          //   SmoothScrolling.scrollTo("content");
          // }, 400);
        }}
      >
        <div className="modelSelector__image">
          <img
            alt="cover"
            src={appConfig.api + this.props.model.cover_small.url}
          />
        </div>

        <div className="modelSelector__content">
          <div className="modelSelector__boatInfo">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9.277"
                height="13.125"
                viewBox="0 0 9.277 13.125"
              >
                <path
                  id="Path_49"
                  data-name="Path 49"
                  d="M-7392.794-14122.486c.8-.184,3.994-.662,4.792-.75l-.53.221q1.524-1.866,3.171-3.607l-.31.748q-.075-2.4,0-4.793l.311.748c-1.1-1.164-2.157-2.361-3.173-3.605l.533.221c-.8-.086-3.994-.566-4.792-.754.8-.184,3.994-.664,4.792-.752l.232-.025.3.246c1.244,1.016,2.442,2.078,3.606,3.172l.3.279.014.471q.074,2.394,0,4.793l-.015.471-.3.277c-1.164,1.094-2.363,2.154-3.606,3.17l-.3.246-.231-.025C-7388.8-14121.822-7392-14122.3-7392.794-14122.486Z"
                  transform="translate(7392.794 14134.836)"
                  fill="#fcd801"
                />
              </svg>
            </div>
            <div className="name">{this.props.model.flat_name}</div>
            <div className="modelSelector__build">BUILD YOURS</div>
          </div>
        </div>

        <div className="modelSelector__exclusivePrice">
          <span>from*</span>{' '}
          <span>
            <strong>
              {convertCurrencyLocation(
                this.props.model.starting_from,
                this.store.location,
                false
              )}
            </strong>
          </span>
        </div>
        {/* <div className="modelSelector__content">
          <div className="modelSelector__name">
            <span
              dangerouslySetInnerHTML={{ __html: this.props.model.name }}
            ></span>
          </div>
          <div className="modelSelector__slogan">
            <span
              dangerouslySetInnerHTML={{ __html: this.props.model.slogan }}
            ></span>
          </div>

          <div className="modelSelector__length">
            <div className="modelSelector__content_title">Length</div>
            <div>
              {this.props.model.standard_spec &&
                this.props.model.standard_spec.length_m}
            </div>
          </div>
          <div className="modelSelector__price">
            <div className="modelSelector__content_title">From*</div>
            <div>
              {convertCurrencyLocation(
                this.props.model.starting_from,
                this.store.location,
                false
              )}
            </div>
          </div>
          <div className="modelSelector__cta">
            CONFIGURE YOUR{' '}
            <span
              dangerouslySetInnerHTML={{ __html: this.props.model.name }}
            ></span>
          </div>
        </div> */}
      </div>
    );
  }
}

@observer
class Tabs extends React.Component {
  state = {
    selected: 0,
  };

  componentDidMount() {
    // console.log(this.props.items);
  }

  selectTab(i) {
    if (i === this.state.selected) {
      this.setState({ selected: -1 });
    } else {
      this.setState({ selected: i });
    }
  }

  render() {
    const { model } = this.props;

    if (!model) return <React.Fragment />;

    return (
      <div className="selectedModel__tabs">
        {model.tabs &&
          model.tabs.map((x, i) => (
            <div
              className={`selectedModel__tabs--item ${this.state.selected === i ? 'selected' : ''
                }`}
              key={i}
            >
              <div
                className="selectedModel__tabs--title"
                onClick={() => this.selectTab(i)}
              >
                {this.state.selected === i ? (
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="tabs-icon"
                    color={model.color}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="tabs-icon"
                    color={model.color}
                  />
                )}
                {x[0]}
              </div>
              <div
                className={`selectedModel__tabs--content ${this.state.selected === i
                  ? 'animate__animated animate__fadeIn'
                  : ''
                  }`}
              >
                <pre dangerouslySetInnerHTML={{ __html: x[1] }} />
              </div>
            </div>
          ))}
      </div>
    );
  }
}

@inject('AppStore')
@observer
class Exclusive extends React.Component {
  store = this.props.AppStore;

  async componentDidMount() {
    const { location } = this.props.match.params;
    // check user
    await this.store.getLocation(location);
    await this.store.loadConfig();

    let user = localStorage.getItem('exclusive:user');

    if (!user) {
      this.store.newPage.loggedIn = false;
      this.store.page.loading = false;
    } else {
      this.store.newPage.loggedIn = true;
      await this.store.getModelGroups();
    }
  }

  render() {
    const { page } = this.store;

    if (page.loading) return <Loading />;

    if (!this.store.newPage.loggedIn) {
      return (
        <div className="app exclusive">
          <div className="header">
            <div className="location">
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 10 }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.639"
                    height="26"
                    viewBox="0 0 20.639 26"
                  >
                    <g
                      id="Group_13"
                      data-name="Group 13"
                      transform="translate(4931.377 -881.681)"
                    >
                      <path
                        id="Path_63"
                        data-name="Path 63"
                        d="M-4921.058,881.681A10.32,10.32,0,0,0-4931.377,892c0,5.7,10.319,15.681,10.319,15.681s10.319-9.982,10.319-15.681A10.32,10.32,0,0,0-4921.058,881.681Zm0,17.47a7.439,7.439,0,0,1-7.439-7.439,7.438,7.438,0,0,1,7.439-7.437,7.439,7.439,0,0,1,7.439,7.437A7.439,7.439,0,0,1-4921.058,899.151Z"
                        fill="#1f1f20"
                      />
                      <g
                        id="Group_12"
                        data-name="Group 12"
                        transform="translate(-4927.325 885.433)"
                      >
                        <path
                          id="Path_64"
                          data-name="Path 64"
                          d="M-4893.032,913.435l-3.355.031-5.537.051a4.5,4.5,0,0,1,4.454-3.879,4.468,4.468,0,0,1,2.986,1.139h-1.9v1.544l3.02-.02A4.354,4.354,0,0,1-4893.032,913.435Z"
                          transform="translate(4903.69 -907.867)"
                          fill="#1f1f20"
                        />
                        <path
                          id="Path_65"
                          data-name="Path 65"
                          d="M-4896.419,936.387V937.9h1.909a4.47,4.47,0,0,1-2.991,1.142,4.49,4.49,0,0,1-4.462-3.959l5.544.051,3.366.031a4.321,4.321,0,0,1-.346,1.214Z"
                          transform="translate(4903.722 -928.286)"
                          fill="#1f1f20"
                        />
                        <path
                          id="Path_66"
                          data-name="Path 66"
                          d="M-4904.608,900.675a6.286,6.286,0,0,0-6.248,5.678l.773-.009a5.513,5.513,0,0,1,5.475-4.9,5.511,5.511,0,0,1,5.509,5.511,5.508,5.508,0,0,1-5.509,5.509,5.5,5.5,0,0,1-5.482-4.99h-.773a6.284,6.284,0,0,0,6.255,5.761,6.287,6.287,0,0,0,6.279-6.279A6.288,6.288,0,0,0-4904.608,900.675Z"
                          transform="translate(4910.864 -900.675)"
                          fill="#fbd700"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
                <span>
                  {this.store.location && this.store.location.displayname}
                </span>
              </span>
            </div>
            <div className="logo-wrapper">
              <a
                href={`${appConfig.locale.url}/${this.store.location_name}`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#000',
                  textDecoration: 'none',
                  fontSize: 12,
                  fontWeight: 'bold',
                }}
              >
                {this.store.configuration && (
                  <img
                    alt="logo"
                    src={
                      appConfig.locale.api + this.store.configuration.logo.url
                    }
                    className="logo"
                  />
                )}
              </a>
            </div>
          </div>
          <div className={`content`}>
            <div>
              <form id="loginform" onSubmit={this.store.fakeLogin}>
                <div className="form">
                  <h2 style={{ textAlign: 'center' }}>Login Form</h2>
                  <div className="form-item">
                    <label>Username</label>
                    <input
                      required
                      type="text"
                      name="username"
                      value={this.store.newPage.username}
                      onChange={(e) =>
                        (this.store.newPage.username = e.target.value)
                      }
                    />
                  </div>
                  <div className="form-item">
                    <label>Password</label>
                    <input
                      required
                      type="password"
                      name="password"
                      value={this.store.newPage.password}
                      onChange={(e) =>
                        (this.store.newPage.password = e.target.value)
                      }
                    />
                  </div>
                  <div style={{ textAlign: 'center', marginTop: 50 }}>
                    <button type="submit" className="button__clean">
                      LOGIN
                    </button>
                  </div>

                  {this.store.newPage.error && (
                    <div
                      style={{
                        color: 'red',
                        textAlign: 'center',
                        marginTop: 15,
                      }}
                    >
                      {this.store.newPage.error}
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="app exclusive">
        <div className="header">
          <div className="location">
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: 10 }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.51 67.01">
                  <g>
                    <g>
                      <circle fill="#f3d004" cx="25.75" cy="25.75" r="24.93" />
                      <path fill="#020202"
                        d="M25.75,0A25.75,25.75,0,0,0,3.29,38.35C9.69,51,25.73,67,25.73,67S41.65,51.13,48.12,38.53a.41.41,0,0,0,.05-.09A25.76,25.76,0,0,0,25.75,0Zm0,45.86A20.13,20.13,0,0,1,5.65,25.75a21.33,21.33,0,0,1,.22-3,20.11,20.11,0,0,1,39.77,0,21.33,21.33,0,0,1,.22,3A20.13,20.13,0,0,1,25.75,45.86Z" />
                      <path fillRule="evenodd"
                        d="M15.11,18.72H42.64A92.62,92.62,0,0,0,24,27.09s14.87-2,23.84,5.07c0,0-7.23-3.08-19.07-2.17S4.15,37.62,4.15,37.62s-1.05.56-.31-.39c0,0,12.1-11,24.58-16.56H15.2a6.55,6.55,0,0,0-3.08.82Z" />
                      <path fill="#f3d004"
                        d="M42.64,18.72A92.62,92.62,0,0,0,24,27.09a40.2,40.2,0,0,1,4.83-.26c5.13,0,13.28.81,19,5.33,0,0-5.49-2.34-14.73-2.34-1.37,0-2.81.05-4.34.17C17,30.94,4.15,37.62,4.15,37.62a1.52,1.52,0,0,1-.47.16c-.14,0-.18-.11.16-.55,0,0,12.1-11,24.58-16.56H15.11a7,7,0,0,0-3,.82l3-2.77H42.64m1.88-.28H15l-.08.07-3,2.78-1.45,1.34,1.77-.88a6.69,6.69,0,0,1,2.86-.8h12c-12,5.62-23.35,16-23.47,16.07l0,0v0c-.25.32-.4.58-.29.81a.36.36,0,0,0,.35.2,1.75,1.75,0,0,0,.6-.2c.12-.07,12.91-6.66,24.55-7.6,1.45-.11,2.9-.17,4.32-.17,9.07,0,14.57,2.3,14.62,2.32l1.95.82-1.66-1.3c-5.66-4.46-13.56-5.4-19.19-5.4-1.5,0-2.75.07-3.61.14A94.71,94.71,0,0,1,42.72,19l1.8-.55Z" />
                    </g>
                  </g>
                </svg>
              </span>
              <span>
                {this.store.location && this.store.location.displayname}
              </span>
            </span>
          </div>
          <div className="logo-wrapper">
            <a
              href={`${appConfig.locale.url}/${this.store.location_name}`}
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#000',
                textDecoration: 'none',
                fontSize: 12,
                fontWeight: 'bold',
              }}
            >
              {this.store.configuration && (
                <img
                  alt="logo"
                  src={appConfig.locale.api + this.store.configuration.logo.url}
                  className="logo"
                />
              )}
            </a>
          </div>
          {this.store.newPage.loggedIn && (
            <div
              style={{
                position: 'absolute',
                right: 15,
                top: 25,
                fontSize: 12,
                cursor: 'pointer',
              }}
            >
              <div
                onClick={(e) => {
                  localStorage.removeItem('exclusive:user');
                  window.location.reload();
                }}
              >
                Logout
              </div>
            </div>
          )}
        </div>
        <div className={`content ${this.store.selectedModel && 'open'}`}>
          <div
            className="modelSelector__left"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
            }}
          >
            <div style={{ fontFamily: 'Michroma' }}>
              <div className="modelSelector__title">
                <span style={{ fontSize: 24 }}>SELECT YOUR</span> <br />{' '}
                <strong style={{ fontSize: 30 }}>
                  DONZI YACHTS EXCLUSIVE SERIES
                </strong>
              </div>
              <div className="modelSelector__titleSlogan">
                Exclusivity is not a privilege, it's a responsibility. You have
                to live up to the hype.
              </div>
            </div>
            {this.store.groups
              .filter((x) => x.exclusive)
              .map((group, i) => (
                <div className="modelSelector__group" key={i}>
                  <div className="modelSelector__groupTitle">
                    <img
                      src={`${appConfig.api}${group.logo.url}`}
                      alt={group.name}
                    />
                    <span style={{ marginLeft: 25, marginRight: 25 }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="9.277"
                        height="13.125"
                        viewBox="0 0 9.277 13.125"
                      >
                        <path
                          id="Path_49"
                          data-name="Path 49"
                          d="M-7392.794-14122.486c.8-.184,3.994-.662,4.792-.75l-.53.221q1.524-1.866,3.171-3.607l-.31.748q-.075-2.4,0-4.793l.311.748c-1.1-1.164-2.157-2.361-3.173-3.605l.533.221c-.8-.086-3.994-.566-4.792-.754.8-.184,3.994-.664,4.792-.752l.232-.025.3.246c1.244,1.016,2.442,2.078,3.606,3.172l.3.279.014.471q.074,2.394,0,4.793l-.015.471-.3.277c-1.164,1.094-2.363,2.154-3.606,3.17l-.3.246-.231-.025C-7388.8-14121.822-7392-14122.3-7392.794-14122.486Z"
                          transform="translate(7392.794 14134.836)"
                          fill="#0a0a0a"
                        />
                      </svg>
                    </span>
                    <span style={{ letterSpacing: 2, fontSize: 12 }}>
                      {group.description}
                    </span>
                  </div>
                  <div className="modelSelector__items">
                    {_.sortBy(group.models, 'order')
                      .filter((x) => x.exclusive)
                      .map((x, i) => (
                        <Model key={i} model={x} store={this.store} />
                      ))}
                  </div>
                </div>
              ))}
          </div>

          {this.store.selectedModel && (
            <div
              id="content"
              className="modelSelector__rightBackdrop"
              onClick={(e) => {
                this.store.selectedModel = undefined;
              }}
            >
              <div
                className={`modelSelector__right ${this.store.selectAnimate
                  ? 'animate__animated animate__fadeInRight'
                  : ''
                  }`}
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  className="modelSelector__right--close"
                  onClick={(e) => {
                    this.store.selectedModel = undefined;
                  }}
                >
                  <AiOutlineClose fontSize="32" />
                </div>
                <div
                  className="selectedModel__title"
                  style={{ textAlign: 'center' }}
                  dangerouslySetInnerHTML={{
                    __html: this.store.selectedModel.name,
                  }}
                ></div>
                <div className="selectedModel__tech">
                  <div className="selectedModel__subtitle">
                    <div className="selectedModel__subtitle--top">
                      TECHNICAL
                    </div>
                    <div className="selectedModel__subtitle--bottom">
                      SPECIFICATIONS
                    </div>
                  </div>
                  <div className="selectedModel__specs">
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">Length</div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.length_m}
                      </div>
                    </div>
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">Beam</div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.beam}
                      </div>
                    </div>
                    {this.store.selectedModel.standard_spec &&
                      this.store.selectedModel.standard_spec.beam_opened && (
                        <div className="selectedModel__spec">
                          <div className="selectedModel__spec--key">
                            Beam with open Side Teracces
                          </div>
                          <div className="selectedModel__spec--text">
                            {this.store.selectedModel.standard_spec &&
                              this.store.selectedModel.standard_spec
                                .beam_opened}
                          </div>
                        </div>
                      )}
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">Draft</div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.draft}
                      </div>
                    </div>
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">
                        Displacement
                      </div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.displacement}
                      </div>
                    </div>
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">Passengers</div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.passengers}
                      </div>
                    </div>
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">Berths</div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.berths}
                      </div>
                    </div>
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">Fuel Cap.</div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.fuel}
                      </div>
                    </div>
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">Range</div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.range}
                      </div>
                    </div>
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">
                        Fresh Water Cap.
                      </div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.water}
                      </div>
                    </div>
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">
                        Classification
                      </div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.classification}
                      </div>
                    </div>
                  </div>

                  <div className="selectedModel__engines">
                    <div className="selectedModel__subtitle">
                      <div className="selectedModel__subtitle--top">ENGINE</div>
                      <div className="selectedModel__subtitle--bottom">
                        TYPES
                      </div>
                    </div>

                    {this.store.selectedModel.standard_spec &&
                      this.store.selectedModel.standard_spec.engine1_type && (
                        <div className="selectedModel__engine">
                          <table>
                            <tbody>
                              <tr>
                                <td>Engine Type</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine1_type
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Propulsion</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine1_propulsion
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Fuel Type</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine1_fueltype
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Engines</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine1_engine
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Speed</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine1_speed
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}

                    {this.store.selectedModel.standard_spec &&
                      this.store.selectedModel.standard_spec.engine2_type && (
                        <div className="selectedModel__engine">
                          <table>
                            <tbody>
                              <tr>
                                <td>Engine Type</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine2_type
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Propulsion</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine2_propulsion
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Fuel Type</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine2_fueltype
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Engines</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine2_engine
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Speed</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine2_speed
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}

                    {this.store.selectedModel.standard_spec &&
                      this.store.selectedModel.standard_spec.engine3_type && (
                        <div className="selectedModel__engine">
                          <table>
                            <tbody>
                              <tr>
                                <td>Engine Type</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine3_type
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Propulsion</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine3_propulsion
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Fuel Type</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine3_fueltype
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Engines</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine3_engine
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Speed</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine3_speed
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}

                    {this.store.selectedModel.standard_spec &&
                      this.store.selectedModel.standard_spec.engine4_type && (
                        <div className="selectedModel__engine">
                          <table>
                            <tbody>
                              <tr>
                                <td>Engine Type</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine4_type
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Propulsion</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine4_propulsion
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Fuel Type</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine4_fueltype
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Engines</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine4_engine
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Speed</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine4_speed
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                  </div>
                </div>

                {this.store.selectedModel.extra_features && (
                  <div className="selectedModel__standard">
                    <div className="selectedModel__subtitle">
                      <div className="selectedModel__subtitle--top">
                        EXTRA INCLUDED
                      </div>
                      <div className="selectedModel__subtitle--bottom">
                        STANDARD FEATURES
                      </div>
                    </div>
                    <div
                      className="selectedModel__standardspecs"
                      style={{ paddingTop: 0 }}
                    >
                      <pre
                        dangerouslySetInnerHTML={{
                          __html: this.store.selectedModel.extra_features,
                        }}
                      ></pre>
                    </div>
                  </div>
                )}

                <div className="selectedModel__standard">
                  <div className="selectedModel__subtitle">
                    <div className="selectedModel__subtitle--top">STANDARD</div>
                    <div className="selectedModel__subtitle--bottom">
                      SPECIFICATIONS
                    </div>
                  </div>
                  <div className="selectedModel__standardspecs">
                    <Tabs model={this.store.selectedModel} />
                    <div
                      style={{ fontSize: 12, marginTop: 35, marginBottom: 100 }}
                    >
                      Specifications are based on available information at the
                      time. Actual specifications may vary. Donzi Yachts
                      reserves the right to change product line specifications,
                      equipment, color scheme and pricing, or to discontinue
                      models at any time without notice or obligation.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="footer modelSelector__footer">
          <div className="modelSelector__footer--left">
            <div style={{ fontSize: 12, paddingRight: 10, paddingLeft: 10 }}>
              *Manufacturer’s Suggested Retail Price (MSRP), excludes options,
              taxes, title and registration processing.
            </div>
          </div>
          <div className="modelSelector__footer--right">
            <div className="button-wrapper">
              {this.store.user && this.store.user.user.factory_order && (
                <div
                  className="modelSelector__cleanbutton"
                  style={{ marginRight: 15 }}
                >
                  <a href={`/${this.store.location_name || 'fl'}/builds`}>
                    BUILDS
                  </a>
                </div>
              )}

              {this.store.selectedModel && (
                <div className="modelSelector__cleanbutton">
                  <a
                    href={`/${this.store.location_name || 'fl'}/build/${this.store.selectedModel.slug
                      }`}
                  >
                    NEXT
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Exclusive;
