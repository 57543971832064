import moment from 'moment';
import { appConfig } from './config';

export const dateFormat = (date_str) => {
  if (!date_str) return '';

  var date = new Date(date_str);
  return new Intl.DateTimeFormat('en-US').format(date);
};

export const convertCurrencyOld = (price, hasPlus) => {
  if (typeof price === 'object') {
    if (!price) return 'Included';

    price = price[appConfig.locale.currency];

    if (price === -1) return 'TBA';
    if (price === -2) return 'Per Design';
    if (price === -3) return 'Select Lounge Layout';
    if (price === -4) return 'Standard';
    if (price === -5) return 'Select';
    if (!price) return 'Included';
  }

  const currency = new Intl.NumberFormat(appConfig.culture, {
    style: 'currency',
    currency: appConfig.locale.currency,
  }).format(price);

  return hasPlus ? `+ ${currency}` : currency;
};

export const convertCurrency = (price, location, dashed = false) => {
  if (price === 0 && dashed) return '-';
  if (price === 0) return 'Not Included';

  const currency = new Intl.NumberFormat(location.culture, {
    style: 'currency',
    currency: location.currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(price);

  return currency;
};

export const convertCurrencyLocation = (
  price,
  location,
  hasPlus,
  exclusive = false
) => {
  if (typeof price === 'object') {
    if (!price) return 'TBA';

    price = price[location.currency];

    if (price === -1) return 'TBA';
    if (price === -2) return 'Per Design';
    if (price === -3) return 'Select Lounge Layout';
    if (price === -4) {
      return exclusive ? 'Pre-selected' : 'Standard';
    }
    if (price === -5) return 'Select';
    if (!price) return 'Included';
  }

  const currency = new Intl.NumberFormat(location.culture, {
    style: 'currency',
    currency: location.currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(price);

  return hasPlus ? `+ ${currency}` : currency;
};

export const convertCurrencySummaryOld = (price, hasPlus) => {
  if (typeof price === 'object') {
    if (!price) return 'Included';

    price = price[appConfig.locale.currency];

    if (price === -1) return 'TBA';
    if (price === -2) return 'Per Design';
    if (price === -3) return 'Select Lounge Layout';
    if (price === -4) return 'Standard';
    if (price === -5) return 'Selected';
    if (!price) return 'Included';
  }

  const currency = new Intl.NumberFormat(appConfig.culture, {
    style: 'currency',
    currency: appConfig.locale.currency,
  }).format(price);

  return hasPlus ? `+ ${currency}` : currency;
};

export const convertCurrencySummaryLocation = (
  price,
  location,
  hasPlus,
  exclusive
) => {
  if (typeof price === 'object') {
    if (!price) return 'Included';

    price = price[location.currency];

    if (price === -1) return 'TBA';
    if (price === -2) return 'Per Design';
    if (price === -3) return 'Select Lounge Layout';
    if (price === -4) {
      return exclusive ? 'Pre-selected' : 'Standard';
    }
    if (price === -5) return 'Selected';
    if (!price) price = 0;
  }

  const currency = new Intl.NumberFormat(location.culture, {
    style: 'currency',
    currency: location.currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(price);

  return hasPlus ? `+ ${currency}` : currency;
};

export const datePicker = (model) => {
  var dates = [];

  for (var i = 0; i < 24; i++) {
    var date = moment().add(i, 'months');
    var text = date.format('MMMM, YYYY');
    var item = { value: date.format('MM/DD/YYYY'), text, disabled: false };

    if (model === 'XLV') {
      let valid_after = moment('2024-05-01');

      if (date < valid_after) {
        item.text = item.text + ' - Sold out';
        item.disabled = true;
      }
    } else if (model === 'LIV') {
      let valid_after = moment('2024-05-01');

      if (date < valid_after) {
        item.text = item.text + ' - Sold out';
        item.disabled = true;
      }
    } else {
    }

    dates.push(item);
  }

  return dates;
};

export const sortingComparer = (a, b) => {
  if (a.sorting > b.sorting) return -1;
  if (a.sorting < b.sorting) return 1;
  return 0;
};
