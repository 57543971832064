const locale = {
  local: {
    key: 'local',
    name: 'LIMITLESS',
    multi_language: false,
    has_payment: false,
    culture: 'en-US',
    currency: 'usd',
    symbol: '$',
    lang: 'en',
    api: 'http://localhost:8000',
    url: 'http://localhost:3000',
  },
  test: {
    key: 'test',
    name: 'LIMITLESS',
    multi_language: false,
    has_payment: false,
    culture: 'en-US',
    currency: 'usd',
    symbol: '$',
    lang: 'en',
    api: 'https://api.donzi-yachts.com',
    url: 'http://localhost:3000',
  },
  xlv: {
    key: 'xlv',
    name: 'Donzi',
    multi_language: false,
    has_payment: false,
    culture: 'en-US',
    currency: 'usd',
    symbol: '$',
    lang: 'en',
    api: 'https://api.donzi-yachts.com',
    url: 'https://configurator.donzi-yachts.com',
  },
};

let { REACT_APP_LOCALE } = process.env;
if (!REACT_APP_LOCALE) REACT_APP_LOCALE = 'local';

const selected_locale = locale[REACT_APP_LOCALE];

export const appConfig = {
  api: selected_locale.api,
  locale: selected_locale,
};
